import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Hello: React.FC = () => {
  const [profile, setProfile] = useState({
    name: '',
    bio: '',
    resume_link: '',
    image_url: ''
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('/api/profile');
        setProfile(response.data);
      } catch (error) {
        console.error('Failed to fetch profile:', error);
        setError('Failed to load profile data.');
      }
    };

    fetchProfile();
  }, []);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="text-gray-200 px-4 py-8">
      <div className="flex flex-col items-center gap-4">
        <h1 className="text-4xl sm:text-3xl md:text-4xl lg:text-6xl xl:text-7xl font-bold mb-4">{profile.name}</h1>
        {profile.image_url && (
          <img src={profile.image_url} alt="Profile" className="w-full max-w-xs sm:max-w-sm md:max-w-sm lg:max-w-md object-cover rounded-sm shadow-xl"/>
        )}
        <div className="w-full text-center">
          <p className="text-gray-100 text-xs sm:text-sm md:text-base lg:text-lg mb-8">{profile.bio}</p>
          {profile.resume_link && (
            <a href={profile.resume_link} target="_blank" rel="noopener noreferrer" className="mt-4 bg-slate-700 hover:bg-slate-800 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out">View Resume</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hello;
