import React, { useState } from 'react';
import Modal from './Modal';
import { useAuth } from '../AuthProvider';
import axios from 'axios';

interface CreateLinkModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const CreateLinkModal: React.FC<CreateLinkModalProps> = ({
  show,
  onHide,
  onSuccess,
}) => {
  const [url, setUrl] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) {
      console.error("No token found");
      setErrorMessage("Unauthorized: No token found.");
      return;
    }

    const formData = new FormData();
    formData.append('url', url);
    if(file) formData.append('image', file);

    try {
      await axios.post('/api/links/create', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      // Reset form
      setUrl('');
      setFile(null);
      setErrorMessage('');
      onSuccess(); // Callback to refresh the list of links or provide user feedback
      onHide(); // Close the modal
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to create link');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Create Link</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <div className="text-red-400 mb-4">{errorMessage}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-3">
            <label htmlFor="url" className="block text-sm font-medium text-gray-300">URL</label>
            <input
              type="text"
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              id="url"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              required />
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="block text-sm font-medium text-gray-300">Image</label>
            <input
              type="file"
              className="mt-1 block w-full text-sm text-gray-300 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-white hover:file:bg-gray-500"
              id="image"
              onChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setFile(e.target.files[0]);
                }
              }}
              required />
          </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Create Link
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600">Close</button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateLinkModal;
