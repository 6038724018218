import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GitHubCalendar from 'react-github-calendar';
interface Project {
  id: string;
  title: string;
  description: string;
  image_url: string;
  github_link: string;
  tags: string[];
}

const tagColorMapping: { [key: string]: string } = {
  "Python": "bg-yellow-400",
  "Django": "bg-green-400",
  "HTML": "bg-purple-400",
  "CSS": "bg-red-500",
  "PyGame": "bg-orange-400",
  "Flask": "bg-lime-500",
  "MongoDB": "bg-green-700",
  "C++": "bg-blue-400",
  "gRPC": "bg-orange-500",
  // Additional mappings as needed
};

const getColorForTag = (tag: string) => {
  return tagColorMapping[tag] || "bg-gray-200";
};

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('/api/projects');
        setProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  return (
    <div className="container mx-auto p-4 mb-32">
      <h2 className="text-2xl sm:text-4xl font-semibold mb-8 text-center">Projects</h2>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6"> {/* Responsive grid layout */}
        {projects.map((project) => (
          <a href={project.github_link} target="_blank" rel="noopener noreferrer" key={project.id}>
            <div key={project.id} className="bg-slate-500 rounded-lg overflow-hidden shadow-lg transform transition duration-500 hover:scale-105"> {/* Subtle hover effect */}
              {project.image_url && (
                <img src={project.image_url} alt={project.title} className="w-full h-32 sm:h-48 md:h-56 object-cover" />
              )}
              <div className="p-3 sm:p-4"> {/* Responsive padding */}
                <div className="font-bold text-md sm:text-lg mb-2">{project.title}</div> {/* Responsive title size */}
                <p className="line-clamp-5 text-gray-100 text-xs">{project.description}</p> {/* Responsive text size */}
              </div>
              <div className="px-3 sm:px-4 pb-3 sm:pb-4 flex flex-wrap"> {/* Responsive padding */}
                {project.tags.map((tag, index) => (
                  <span key={index} className={`inline-block rounded-full px-2 py-1 text-xs sm:text-sm font-semibold text-gray-700 mr-2 mb-2 ${getColorForTag(tag)}`}>
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </a>
        ))}
      </div>

      <h1 className="text-xl font-bold mb-2 mx-10 mt-10">Github Contributions Within Last Year</h1>
      <div className="bg-slate-700 mt-4 mb-8 p-4 sm:p-6 md:p-8 flex justify-center items-center rounded-lg shadow-xl max-w-5xl mx-auto">
          <GitHubCalendar username="masonhgn" />
      </div>


    </div>
  );
};

export default Projects;
