import React, { useState } from 'react';
import Modal from './Modal';
import { useAuth } from '../AuthProvider';
import axios from 'axios';

interface CreateProjectModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
  show,
  onHide,
  onSuccess,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [githubLink, setGithubLink] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useAuth();

  const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTags(e.target.value.split(',').map(tag => tag.trim()));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) {
      console.error("No token found");
      setErrorMessage("Unauthorized: No token found.");
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if(imageFile) formData.append('image', imageFile);
    formData.append('github_link', githubLink);
    formData.append('tags', JSON.stringify(tags));

    try {
      await axios.post('/api/projects/create', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setTitle('');
      setDescription('');
      setImageFile(null);
      setGithubLink('');
      setTags([]);
      setErrorMessage('');
      onSuccess();
      onHide();
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to create project');
    }
  };

  return (
  <Modal show={show} onHide={onHide}>
    <Modal.Header>
      <Modal.Title>Create Project</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-sm font-medium text-gray-300">Title</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-300">Description</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={4}
            className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="githubLink" className="block text-sm font-medium text-gray-300">GitHub Link</label>
          <input
            type="url"
            id="githubLink"
            value={githubLink}
            onChange={(e) => setGithubLink(e.target.value)}
            className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>
        <div>
          <label htmlFor="tags" className="block text-sm font-medium text-gray-300">Tags (comma-separated)</label>
          <input
            type="text"
            id="tags"
            value={tags.join(', ')}
            onChange={handleTagsChange}
            className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="image" className="block text-sm font-medium text-gray-300">Project Image</label>
          <input
            type="file"
            className="mt-1 block w-full text-sm text-gray-300 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-white hover:file:bg-gray-500"
            id="image"
            onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
            required />
        </div>
        <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          Create Project
        </button>
      </form>
    </Modal.Body>
    <Modal.Footer>
      <button onClick={onHide} className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600">
        Close
      </button>
    </Modal.Footer>
  </Modal>

  );
};

export default CreateProjectModal;
