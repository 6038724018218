import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface Link {
  id: string;
  url: string;
  image_url: string;
}

const Links: React.FC = () => {
  const [links, setLinks] = useState<Link[]>([]);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const response = await axios.get('/api/links');
        setLinks(response.data); // Assuming the response data is an array of Link objects
      } catch (error) {
        console.error('Error fetching social links:', error);
      }
    };

    fetchLinks();
  }, []);

return (
  <div className="flex justify-center items-center space-x-4 p-4">
    {links.map((link) => (
      <a
        key={link.id}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center opacity-70 w-8 h-8 hover:opacity-100 transform transition duration-150 ease-in-out"> {/* Removed rounded-full, bg-gray-200, overflow-hidden */}
        <img
          src={link.image_url}
          className="w-full h-full" 
        />
      </a>
    ))}
  </div>
);

};

export default Links;
