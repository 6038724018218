import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Define the Research interface
interface ResearchInterface {
  id: string;
  title: string;
  description: string;
  paper_url: string;
}

const Research: React.FC = () => {
  // Use the Research interface to type the state
  const [researchItems, setResearchItems] = useState<ResearchInterface[]>([]);

  useEffect(() => {
    const fetchResearch = async () => {
      try {
        const response = await axios.get('/api/research');
        setResearchItems(response.data); // Assuming the response data is an array of Research objects
      } catch (error) {
        console.error('Error fetching research items:', error);
        // Optionally handle the error, e.g., set an error state, show a message, etc.
      }
    };

    fetchResearch();
  }, []);

  return (
    <div className="container mx-auto px-4 py-4 mb-32 text-center">
      <h2 className="text-2xl sm:text-4xl font-semibold mb-8">Research</h2>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
      <div className="grid grid-cols-1 gap-4">
        {researchItems.map((item) => (
          <a href={item.paper_url} target="_blank" rel="noopener noreferrer" key={item.id} className="block bg-slate-500 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
            <div className="p-6 cursor-pointer h-full flex flex-col">
              <h3 className="text-xl font-semibold text-white-200 mb-4">{item.title}</h3>
              <p className="text-white-300 flex-1 overflow-hidden text-ellipsis line-clamp-3 mb-4">{item.description}</p>
              <div className="text-white-400 hover:text-blue-600 underline">Read Paper</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
  
};

export default Research;
