import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import { useAuth } from '../AuthProvider';
import axios from 'axios';

interface UpdateProfileModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
  userProfile: any; // Enhance typing if possible for better code quality
}

const UpdateProfileModal: React.FC<UpdateProfileModalProps> = ({
  show,
  onHide,
  onSuccess,
  userProfile,
}) => {
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useAuth();

  useEffect(() => {
    if (userProfile) {
      setName(userProfile.name || '');
      setBio(userProfile.bio || '');
    }
  }, [userProfile]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!token) {
      setErrorMessage("Unauthorized: No token found.");
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('bio', bio);
    if (resumeFile) formData.append('resume', resumeFile);
    if (file) formData.append('image', file);

    try {
      await axios.post('/api/profile/update', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      onSuccess();
      onHide();
    } catch (error) {
      setErrorMessage('Failed to update profile');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <div className="text-red-400 mb-4">{errorMessage}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-3">
            <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="bio" className="block text-sm font-medium text-gray-300">Bio</label>
            <textarea
              id="bio"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="resume" className="block text-sm font-medium text-gray-300">Resume (PDF)</label>
            <input
              type="file"
              id="resume"
              accept=".pdf"
              onChange={(e) => setResumeFile(e.target.files ? e.target.files[0] : null)}
              className="mt-1 block w-full text-sm text-gray-300 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-white hover:file:bg-gray-500"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="image" className="block text-sm font-medium text-gray-300">Profile Image</label>
            <input
              type="file"
              id="image"
              onChange={(e) => setFile(e.target.files ? e.target.files[0] : null)}
              className="mt-1 block w-full text-sm text-gray-300 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-white hover:file:bg-gray-500"
            />
            {userProfile && userProfile.image_url && (
              <img src={userProfile.image_url} alt="Current Profile" className="mt-2 w-24 h-24 rounded-full" />
            )}
          </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Update Profile
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600">Close</button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateProfileModal;
