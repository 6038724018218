import React, { useEffect, useState } from 'react';




const About: React.FC = () => {




    return (
        <div className="mb-32">
            <h2 className="text-2xl sm:text-4xl font-semibold mb-8 text-center">About</h2>

            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
            

            <div className="flex flex-col lg:flex-row items-center mb-16">
                <div className="flex-1 text-white p-4 mx-5">
                    <h1 className="text-xl font-bold mb-2">Experience</h1>
                    <p>
                        During my undergrad, I interned as a SWE at a <a href="https://www.proplogix.com/" className="font-medium text-blue-500 dark:text-blue-300 hover:underline"><b>small real estate technology firm</b>
                        </a>, and then a <a href="https://www.keysight.com/us/en/home.html" className="font-medium text-blue-500 dark:text-blue-300 hover:underline"><b>large scale US electronics testing equipment manufacturer</b></a>.
                        At FSU, I had the opportunity to participate in several credit-based research opportunities, assisting my professors with research related to graph algorithms, NLP models, and LLM 
                        dataset generation. Towards the end of Summer 2023, I started work as an undergraduate researcher, creating software for medical students at the FSU college of medicine. During my last semester, I TA'd for two classes 
                        part time (CGS2060, CGS2100) and since then I have continued within the CS department as a researcher, developing and enhancing new methods to predict stock prices.
                    </p>

                </div>

            </div>





            <div className="flex flex-col lg:flex-row items-center mb-16">
                <div className="flex-1 text-white p-4 mx-5">
                    <h1 className="text-xl font-bold mb-2">Competitive Programming</h1>
                    <p>I've enjoyed participating in several competitive programming events. Most recently, I got the opportunity to compete in the 2023 ICPC Southwest regional where
                        my team placed silver.
                    </p>
                    <h3 className="mb-3">Past Events:</h3>
                    <ul className="list-disc mx-6">
                        <li>ICPC Fall 2023 Southwest Regional [2nd place]</li>
                        <li>ACM Fall 2023 Programming Contest [2nd place]</li>
                        <li>ACM Spring 2023 Programming Contest [17th place]</li>
                        <li>ACM Fall 2022 Programming Contest [24th place]</li>
                    </ul>
                </div>
                <div className="flex-1">
                    <img src="https://masonhagan.com/api/static/medal.jpg" alt="contest" className="w-full lg:w-96 rounded-md" />
                </div>
            </div>
        </div>

    );

};

export default About;