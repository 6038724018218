import React, { Children, ReactNode, useEffect, useState } from 'react'

const getChildrenByDisplayName = (children: React.ReactNode, displayName: string) => {
  const childrenArray = React.Children.toArray(children);
  return childrenArray.find((child) => {
    return (child as any).type.displayName === displayName;
  });
};

export interface ModalProps {
  children?: React.ReactNode;
  show?: boolean;
  onHide?: () => void;
  closeOnBackdropClick?: boolean;
  exitButton?: boolean;
}

const Modal = ({ children, show: initialShow, exitButton, onHide }: ModalProps) => {
  const modalHeader = getChildrenByDisplayName(children, 'Header');
  const modalContent = getChildrenByDisplayName(children, 'Content');
  const modalFooter = getChildrenByDisplayName(children, 'Footer');

  const [show, setShow] = useState(initialShow);

  const closeHandler = () => {
    if (onHide) {
      onHide();
    }
    setShow(false);
  };

  return (
    <div className={`${show ? 'fixed' : 'hidden'} inset-0 z-[999] bg-black bg-opacity-70 flex items-center justify-center`}>
      <div className="flex flex-col bg-gray-800 p-8 w-1/2 rounded-lg shadow-lg gap-4 text-white">
        {modalHeader}
        {modalContent}
        {modalFooter}
      </div>
    </div>
  );
};

// Header component
const Header = ({ children, className = '' }: { children?: ReactNode, className?: string }) => (
  <div className={`text-gray-100 ${className}`}>{children}</div>
);
Header.displayName = 'Header';
Modal.Header = Header;

// Title component
const Title = ({ children }: { children: string }) => {
  const innerText = React.Children.toArray(children).join('');
  return <div className="text-xl font-semibold">{innerText}</div>;
};
Title.displayName = 'Title';
Modal.Title = Title;

// Body component
const Body = ({ children, className = '' }: { children: ReactNode, className?: string }) => (
  <div className={`text-gray-300 ${className}`}>{children}</div>
);
Body.displayName = 'Content';
Modal.Body = Body;

// Footer component
const Footer = ({ children, className = '' }: { children: ReactNode, className?: string }) => (
  <div className={`text-gray-100 ${className}`}>{children}</div>
);
Footer.displayName = 'Footer';
Modal.Footer = Footer;

export default Modal;