import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CreateProjectModal from '../modals/CreateProjectModal';
import CreateResearchModal from '../modals/CreateResearchModal';
import UpdateProfileModal from '../modals/UpdateProfileModal';
import CreateLinkModal from '../modals/CreateLinkModal';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';

const Admin: React.FC = () => {
  const [projects, setProjects] = useState<any[]>([]);
  const [research, setResearch] = useState<any[]>([]);
  const [profile, setProfile] = useState<any>(null);
  const [links, setLinks] = useState<any[]>([]);

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [showCreateResearchModal, setShowCreateResearchModal] = useState(false);
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [showCreateLinkModal, setShowCreateLinkModal] = useState(false);

  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    console.log('test');
    // Check if user is authenticated on component mount
    if (!isAuthenticated) {
      navigate('/login'); // Redirect to login page if not authenticated
    } else {
      console.log('hello world');
      // Fetch data only if the user is authenticated
      fetchResearch();
      fetchProjects();
      fetchProfile();
      fetchLinks();
    }
  }, [isAuthenticated, navigate]);


  const getToken = () => {
    return localStorage.getItem('jwtToken');
  };
  
  // Function to delete a project
  const deleteProject = (projectId: string) => {
    const formData = new FormData();
    formData.append('id', projectId);
    axios.post(`/api/projects/delete`, formData, {
      headers: {
        Authorization: `Bearer ${getToken()}` // Include the token in the Authorization header
      }
    })
      .then(() => {
        // Filter out the deleted project from the state
        setProjects(projects.filter(project => project._id !== projectId));
      })
      .catch((error) => {
        console.error('Error deleting project:', error);
        // Optionally handle the error
      });
  };

  // Function to delete a research item
const deleteResearch = (researchId: string) => {
  const formData = new FormData();
  formData.append('id', researchId);
  axios.post(`/api/research/delete`, formData, {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
    .then(() => {
      // Filter out the deleted research item from the state
      setResearch(research.filter(item => item._id !== researchId));
    })
    .catch((error) => {
      console.error('Error deleting research:', error);
    });
};

// Function to delete a link
const deleteLink = (linkId: string) => {
  const formData = new FormData();
  formData.append('id', linkId);
  axios.post(`/api/links/delete`, formData, {
    headers: {
      Authorization: `Bearer ${getToken()}`
    }
  })
    .then(() => {
      // Filter out the deleted link from the state
      setLinks(links.filter(link => link.id !== linkId));
    })
    .catch((error) => {
      console.error('Error deleting link:', error);
    });
};

  
  const fetchLinks = () => {
    axios.get('/api/links')
      .then((response) => {
        setLinks(response.data);
      })
      .catch((error) => {
        console.error('Error fetching links:', error);
        // Optionally handle the error, e.g., set an error state, show a message, etc.
      });
  };

  const fetchProjects = () => {
    axios.get('/api/projects')
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.error('Error fetching projects:', error);
        // Optionally handle the error, e.g., set an error state, show a message, etc.
      });
  };
  
  const fetchResearch = () => {
    axios.get('/api/research')
      .then((response) => {
        setResearch(response.data);
      })
      .catch((error) => {
        console.error('Error fetching research:', error);
        // Optionally handle the error, e.g., set an error state, show a message, etc.
      });
  };

  const fetchProfile = () => {
    axios.get('/api/profile')
      .then((response) => {
        setProfile(response.data);
      })
      .catch((error) => {
        console.error('Error fetching profile:', error);
        // Optionally handle the error, e.g., set an error state, show a message, etc.
      });
  };





  

  useEffect(() => {
    fetchResearch();
    fetchProjects();
    fetchProfile();
    fetchLinks();
  }, []);

  return (
    <div className="bg-gray-900 text-gray-200 min-h-screen p-8">
      <div className="container mx-auto">
        <button 
            onClick={() => navigate('/')} // Step 3: Use navigate to go to the home page
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200"
          >
            Back to Home Page
          </button>
        {isAuthenticated ? 
          <button 
            onClick ={logout}
            className="mt-4 mb-4 ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200"

          >
            Logout
          </button> 
        : <></>
        }
        <h2 className="text-2xl font-bold mb-6">Projects</h2>
        <div className="space-y-4 mb-8">
          {projects.map((project) => (
            <div key={project._id} className="bg-gray-800 p-4 rounded-lg shadow flex justify-between items-center">
              <span>{project.title}</span>
              <button onClick={() => deleteProject(project._id)} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-200">Delete</button>
            </div>
          ))}
          <button onClick={() => setShowCreateProjectModal(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200">Create Project</button>
        </div>
  
        <h2 className="text-2xl font-bold mb-6">Research</h2>
        <div className="space-y-4 mb-8">
          {research.map((item) => (
            <div key={item._id} className="bg-gray-800 p-4 rounded-lg shadow flex justify-between items-center">
              <span>{item.title}</span>
              <button onClick={() => deleteResearch(item._id)} className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-200">Delete</button>
            </div>
          ))}
          <button onClick={() => setShowCreateResearchModal(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200">Create Research</button>
        </div>
  
        <h2 className="text-2xl font-bold mb-6">Profile</h2>
        {profile && (
          <div className="bg-gray-800 p-4 rounded-lg shadow flex justify-between items-center mb-8">
            <span>{profile.name}</span>
            <button onClick={() => setShowUpdateProfileModal(true)} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-200">Edit Profile</button>
          </div>
        )}
  
        <h2 className="text-2xl font-bold mb-6">Links</h2>
        <div className="space-y-4 mb-8">
          {links.map((link) => (
            <div key={link.id} className="bg-gray-800 p-4 rounded-lg shadow flex items-center justify-between">
              <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300 transition duration-200">{link.url}</a>
              <img src={link.image_url} alt="Link" className="w-12 h-12 object-cover rounded-full" />
              <button onClick={() => deleteLink(link.id)}className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-200">Delete</button>
            </div>
          ))}
          <button onClick={() => setShowCreateLinkModal(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200">Create Link</button>
        </div>
      </div>

      {/* Modals */}
      {showCreateProjectModal && <CreateProjectModal 
        show={showCreateProjectModal} 
        onHide={() => setShowCreateProjectModal(false)} 
        onSuccess={() => {
          // Optionally, refresh projects list after creation
          fetchProjects();
          setShowCreateProjectModal(false);
        }}
      />}
      {showUpdateProfileModal && <UpdateProfileModal 
        show={showUpdateProfileModal} 
        onHide={() => setShowUpdateProfileModal(false)} 
        userProfile={profile}
        onSuccess={() => {
          // Optionally, refresh profile information after update
          fetchProfile();
          setShowUpdateProfileModal(false);
        }}
      />}
      {showCreateResearchModal && <CreateResearchModal 
        show={showCreateResearchModal} 
        onHide={() => setShowCreateResearchModal(false)} 
        onSuccess={() => {
          // Optionally, refresh profile information after update
          fetchResearch();
          setShowCreateResearchModal(false);
        }}
      />}

      {showCreateLinkModal && <CreateLinkModal 
        show={showCreateLinkModal} 
        onHide={() => setShowCreateLinkModal(false)} 
        onSuccess={() => {
          fetchLinks(); // Refresh links after creating a new one
          setShowCreateLinkModal(false);
        }}
      />}
    </div>
  );
  
};

export default Admin;
