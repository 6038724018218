import React, { useState } from 'react';
import Modal from './Modal';
import { useAuth } from '../AuthProvider';
import axios from 'axios';

interface CreateResearchModalProps {
  show: boolean;
  onHide: () => void;
  onSuccess: () => void;
}

const CreateResearchModal: React.FC<CreateResearchModalProps> = ({
  show,
  onHide,
  onSuccess,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [paperURL, setPaperURL] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { token } = useAuth();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await axios.post('/api/research/create', {
        title,
        description,
        paper_url: paperURL,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      // Reset form fields
      setTitle('');
      setDescription('');
      setPaperURL('');
      setErrorMessage('');
      onSuccess();
      onHide();
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to create research');
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Create Research</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <div className="text-red-400 mb-4">{errorMessage}</div>}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-3">
            <label htmlFor="title" className="block text-sm font-medium text-gray-300">Research Title</label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required />
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="block text-sm font-medium text-gray-300">Research Description</label>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={4}
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required />
          </div>
          <div className="mb-3">
            <label htmlFor="paperURL" className="block text-sm font-medium text-gray-300">Paper URL</label>
            <input
              type="text"
              id="paperURL"
              value={paperURL}
              onChange={(e) => setPaperURL(e.target.value)}
              className="mt-1 block w-full px-4 py-2 bg-gray-700 text-white border border-gray-600 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              required />
          </div>
          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            Create Research
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className="bg-gray-700 text-white px-4 py-2 rounded-md hover:bg-gray-600">Close</button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateResearchModal;
