import React from 'react';
import { useAuth } from '../AuthProvider';
import { Link } from 'react-router-dom';
// Other imports
import Hello from '../components/Hello';
import Links from '../components/Links';
import Projects from '../components/Projects';
import Research from '../components/Research';
import About from '../components/About';

const Home: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-slate-600 text-white">
      <div className="md:sticky top-0 h-full md:flex-none md:w-2/5 bg-slate-600 p-4 animate-fade-right animate-duration-[1000ms]"> {/* Adjusted for stacking on top on medium and smaller screens */}
        <Hello />
        <Links />
        {isAuthenticated && (
          <div className="mt-4">
            <Link to="/admin" className="inline-block bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Admin Panel
            </Link>
          </div>
        )}
      </div>
      

      
      <div className="flex-grow overflow-auto p-4 animate-fade-left animate-duration-[3000ms] mt-8"> {/* Keeps Projects and Research scrollable */}
        <Projects />
        <About/>
        <Research />
      </div>
    </div>
  );
};

export default Home;
